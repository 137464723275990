const body = document.querySelector(`body`);
const watchBtn = document.querySelector(`.popup__watch-video`);
const getStartedBtn = document.querySelector(`.popup__get-started`);
const overlayFragment = document.createDocumentFragment();
const overlay = document.createElement(`div`);
overlay.classList.add(`overlay`);
overlay.style.overflow = `auto`;

const popup = document.createElement(`div`);
popup.classList.add(`popup`);

const createVideoPopup = (fragment) => {
  popup.classList.add(`popup--video`);
  popup.innerHTML = `
  <svg class="popup__close" width="30" height="30">
    <use xlink:href="passhub.ch/img/sprite.svg#icon-close"></use>
  </svg>
  <div class="popup__wrapper popup__wrapper--video">
    <iframe class="popup__video" src="https://www.youtube-nocookie.com/embed/WxIowW1JML8?controls=1" frameborder="0" allow="accelerometer; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
  </div>
  `;
  fragment.appendChild(popup);
}

const createGetStartedPopup = (fragment) => {
  popup.classList.add(`popup--getstarted`);
  popup.innerHTML = window.getStartedLayout;
  fragment.appendChild(popup);
}

const findOverlatTop = (element) => {
  // element.style.top = `${window.pageYOffset}px`;
  element.style.top = `0px`;
}

const openVideo = () => {
  createVideoPopup(overlay);
  findOverlatTop(overlay);
  overlayFragment.appendChild(overlay);
  body.appendChild(overlayFragment);
  body.style.overflow = `hidden`;
};

const openGetStarted = () => {
  createGetStartedPopup(overlay);
  findOverlatTop(overlay);
  overlayFragment.appendChild(overlay);
  body.appendChild(overlayFragment);
  body.style.overflow = `hidden`;
};

const removePopup = () => {
  overlay.remove();
  popup.remove();
  popup.classList = `popup`;
  body.style.overflow = `auto`;
};

const closePopup = (evt) => {
  if (evt.target !== overlay) {
    return;
  } else {
    removePopup();
  }
};

watchBtn && watchBtn.addEventListener(`click`, (evt) => {
  evt.preventDefault();
  openVideo();

  overlay.addEventListener(`click`, closePopup);
});

getStartedBtn && getStartedBtn.addEventListener(`click`, (evt) => {
  evt.preventDefault();
  openGetStarted();

  overlay.addEventListener(`click`, closePopup);
  document.addEventListener(`keydown`, (evt) => {
    if (evt.keyCode === 27) {
      removePopup();
    } else {
      return;
    }
  });
});

